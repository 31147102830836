import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Img from 'gatsby-image';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    gasleak: file(relativePath: { eq: "dispersion/gasleak.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const DispersionPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO
        title={'Gas dispersion. Read about which services we can provide you with in this field.'}
      />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-48 mb-48">
          <div>
            <h1 className={styles.welcomeText}>Gas dispersion</h1>
            <p className={styles.descriptionText}>
              Gas dispersion studies of various types are also offered, predicting consequences and
              risk from releases of flammable or toxic gases/liquids, both indoor (natural or forced
              ventilation) and outdoors. CFD simulations of dispersion can often explain smell from
              or exposure to harmful substances, or hazardous (flammable) zones from a stack or
              emission source. By analysing the design, non-tolerable situations can be identified
              and improved by redesign, mitigation or adjustment of operational procedures. Typical
              substances modelled may be LNG, natural gas, other alkane or aromatic hydrocarbons,
              chlorine, SO2, CO2 and more.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.gasleak.childImageSharp.fluid} alt="Gas leakage" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
          <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2012.07.006"
                target="_blank"
                rel="noopener noreferrer"
                title="Equivalent cloud
                methods used for explosion risk and consequence studies."
              >
                Hansen, O. R., Gavelli, F., Davis, S. G., Middha, P. (2013). Equivalent cloud
                methods used for explosion risk and consequence studies.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2010.08.005"
                target="_blank"
                rel="noopener noreferrer"
                title="Validation of FLACS against experimental data sets from the model evaluation database for LNG vapor dispersion."
              >
                Hansen, O. R., Gavelli, F., Ichard, M., Davis, S. G. (2010). Validation of FLACS
                against experimental data sets from the model evaluation database for LNG vapor
                dispersion.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.atmosenv.2008.09.081"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD model simulation
                of dispersion from chlorine railcar releases in industrial and urban areas."
              >
                Hanna, S. R., Hansen, O. R., Ichard, M., Strimaitis, D. (2009). CFD model simulation
                of dispersion from chlorine railcar releases in industrial and urban areas.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1175/BAMS-87-12-1713"
                target="_blank"
                rel="noopener noreferrer"
                title="Detailed Simulations of
                Atmospheric Flow and Dispersion in Downtown Manhattan: An Application of Five
                Computational Fluid Dynamics Models."
              >
                Hanna, S. R., Brown, M. J., Camelli, F. E., Chan, S. T., Coirier, W. J., Hansen, O.
                R., Huber, A. H., Kim, S., Reynolds, R. M. (2006). Detailed Simulations of
                Atmospheric Flow and Dispersion in Downtown Manhattan: An Application of Five
                Computational Fluid Dynamics Models.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.atmosenv.2004.05.041"
                target="_blank"
                rel="noopener noreferrer"
                title="FLACS CFD air quality model
                performance evaluation with Kit Fox, MUST, Prairie Grass, and EMU observations."
              >
                Hanna, S. R., Hansen, O. R., Dharmavaram, S. (2004). FLACS CFD air quality model
                performance evaluation with Kit Fox, MUST, Prairie Grass, and EMU observations.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DispersionPage;
